import React from 'react';
import {Link} from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'
import OurMissionShape from '../../assets/images/our-mission/our-mission-shape2.png'
import OurMissionImg from '../../assets/images/our-mission/our-mission1.png'
import OurMissionShape1 from '../../assets/images/our-mission/our-mission-shape1.png'

const OurMission = () => {
    return (
        <section className="our-mission-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                {/* <span className="sub-title">
                                    <img src={StarIcon} alt="image" /> 
                                    Our Mission
                                </span> */}
                                <h2>Machine Learning & Sensor Data Analysis</h2>
                                <p>
                                We use machine learning to help fleets, drivers and manufacturers achieve their goals. Whether you want to make the most out of your sensor data or need to sell or purchase sensors, we can help.
                                </p>
                                <p>
                                Equipment failures cause downtime and downtime costs money. Predicting these failures can prevent and reduce the impact that downtime has on your bottom line. Sensor data captured in real-time and processed with advanced technologies can be turned into actionable strategies for predictive maintenance.
                                </p>
                                
                                <ul className="our-mission-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Industry Experience
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        We Get Results
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Business Strategy
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Expert Prepectives
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
           
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={OurMissionImg} alt="image" />
                            <div className="shape">
                                <img src={OurMissionShape1} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurMission;