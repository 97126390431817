import React from 'react';
import {Link} from 'gatsby'

import StarIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={StarIcon} alt="image" /> 
                        Our Solutions
                    </span>
                    <h2>From The Street to the Boardroom</h2>
                    <p>Today’s vehicles produce an enormous amount of sensor data, but make use of it isn’t that easy. But whether it’s in the boardroom or on the streets, we can put that sensor data to work for you. Our technology and know-how can help you reach your goals. Whether your goal is improving the bottom line on your fleet operations, eliminating maintenance costs, or reducing risk, we can help. </p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                {/* <Link to="/service-details">
                                    IoT Applications
                                </Link> */}
                                IoT Applications
                            </h3>
                            {/* TODO */}
                                <p>
                                    IoT captures data in ways never before possible. 
                                    Instant machine learn can now be performed to gain new insights and better control. 
                                </p>

                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                {/* <Link to="/service-details">
                                    Sensor and ML Solutions
                                </Link> */}
                                Sensor and ML Solutions
                            </h3>

                            <p>
                                Our solutions leverage open source technologies like TensorFlow and scikit-learn along with our own tools to build world-class systems based on sensor data.
                            </p>
                            
                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                {/* <Link to="/service-details">
                                    We Love It When A Plan Comes Together
                                </Link> */}
                                We Love It When A Plan Comes Together
                            </h3> 

                            <p>
                                Team up with us to gather and analyze the intelligence you need to fuel your strategy.</p>
                            
                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;