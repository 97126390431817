import React from "react";
import { Link } from "gatsby";
import ReactWOW from "react-wow";

import MainImage from "../../assets/images/main-banner/main-pic1.png";

const MainBanner = () => {
  return (
    <div className="main-banner-area">
      <div className="container">
        <div className="banner-area-content">
          <ReactWOW delay=".1s" animation="fadeInLeft">
            <h1>AI, ML & IoT Consulting</h1>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInLeft">
            <p>
              We use machine learning to help fleets, drivers and manufacturers achieve their goals. Whether you want to make the most out of your sensor data or need to sell or purchase sensors, we can help.
            </p>
          </ReactWOW>

          <ReactWOW delay=".1s" animation="fadeInUp">
            <img src={MainImage} alt="image" />
          </ReactWOW>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
