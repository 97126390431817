import React from 'react';

import StarIcon from '../../assets/images/star-icon.png'
import HowitsWork from '../../assets/images/how-its-work.png'

const HowItWork = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={StarIcon} alt="image" /> 
                                How We Get Things Done
                            </span>
                            <h2>The Iterative Design & Data Science Process</h2>
                            <p>Our process is based on the Desgin Thinking and the Deming cycle to create a "plan-do-check-act" strategy that combines continuous improvement with machine learning.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Plan</h3>
                                    <p>.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Do</h3>
                                    <p>.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Study</h3>
                                    <p>.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        4
                                    </div>
                                    <h3>Act</h3>
                                    <p>.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={HowitsWork} alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;